import { Spin } from 'antd';
import React, { useState, useEffect } from 'react';

interface IProps {
  image: any;
}

const RenderImage = (props: IProps) => {
  const { image } = props;
  const isFileObject = !image.url && image.name;
  const fileURL = image.url || (isFileObject ? URL.createObjectURL(image) : null);

  const [fileType, setFileType] = useState(image.type || null);
  const [isLoading, setLoading] = useState(false);

  const fetchFileType = async () => {
    if (!fileType && fileURL) {
      try {
        setLoading(true);
        const response = await fetch(fileURL, { method: 'HEAD' });
        const contentType = response?.headers?.get('Content-Type');
        setFileType(contentType && contentType.includes('pdf') ? 'pdf' : 'image');
      } catch (error) {
        setFileType('image');
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchFileType();
  }, [fileURL, fileType]);

  if (isLoading) {
    return <Spin style={{ marginLeft: '50%' }} />;
  }
  console.log({ fileType, props });
  return (
    <div style={{ background: '#CCCCCC' }}>
      {fileType === 'pdf' ? (
        <embed src={fileURL} style={{ width: '100%', minHeight: 400 }} />
      ) : (
        <img src={fileURL} alt="img" style={{ width: '100%' }} />
      )}
    </div>
  );
};

export default RenderImage;
