import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../theming/jssTypes';
import { Drawer, Menu, Tag } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeSideMenu } from '../../actions/navigationActions';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
    getRetailTripManagerRoute,
    getDownloadsRoute,
    getDomesticCustomerActivityRoute,
    getDomesticTrackRoute,
    getRetailTrackingRoute,
    getRiderReconciliationRoute,
    getInvoiceRoute,
    getLTLAnalyicsRoute,
    getAutoAllocateRequestsPageRoute,
    getRetailAnalyticsOverviewRoute,
    getSummaryMetricsRoute,
    getRetailSupportPortalRoute,
    getRetailERPSyncRoute,
    getRetailStoreViewRoute,
    getTransactionsHistoryRoute,
    getBankDepositRoute,
    getBankTransactionsHistoryRoute,
    getCashRemittanceRoute,
    getSupplementoryServicesRoute,
    getNetworkRoute,
    getShelfBaseRoute,
    getThirdPartyViewRoute,
    getCRMInOpsRoute,
    getPrintLabelRequestRoute,
    getCallsOverviewRoute,
    getMyCallsRoute,
    getDevToolsRoute,
    getIntegrationMarketplaceRoute,
    getRoutingPlaygroundRoute,
    getTerritoryOptimizationRoute,
    getFoodDashboardOrderManagement,
    getFoodDashboardReporting,
    getFoodDashboardDispatchScreen,
    getFoodDashboardCODCashSettlement,
    getFoodDashboardAnalytics,
    getFoodDashboardRosterManagement,
    getFoodDashboardRiderManagement,
    getFoodDashboardNotifications,
    getCustomTripRequestsRoute,
    getSlotManagementBaseRoute,
    getSupplyRequestRoute,
    getConsignmentUploadRequestRoute,
    getCarrierTransactionsHistoryRoute,
    getGeocodingPlaygroundRoute,
    getVehicleRequestRoute,
    getCarrierReconciliationRoute,
    getPageBuilderDashboardRoute,
    getLiveDashboardRoute,
    getGenericDownloadRequestsRoute,
    getPlaneTicketsRoute,
} from '../../routing/utils';
import {
    MOBILE_BREAK_POINT, applicationType, HIDE_RETAIL_CUSTOMER_SUPPORT_PORTAL,
    HIDE_RETAIL_STORE_HUB_CHANGES,
    NAVBAR_HEIGHT,
} from '../../globals';
import { logoutUserAndClearCache } from '../../api/utils';
import {
    getTmsOverviewRoute,
    getTmsVehicleRoute,
    getTmsISTRoute,
    getTmsBagRoute,
    getTmsDownloadRequestRoute,
    getTmsVehicleAttendanceRoute,
} from '../pages/TMSDashboard/routes';
import { getPISSummaryRoute, getManagePISRoute } from '../pages/PayinSlip/routes';
import { getVendorPayoutRoute } from '../pages/VendorPayout/routes';
import {
    getExpressCRMRoute,
} from '../pages/ExpressDashboard/routes';
import { getOpsV1AttendanceRoute, getOpsV1CoordinatorPortalRoute, getOpsV1DeliveryTaskListRoute, getOpsV1KmReadingRoute, getOpsV1MediumListRoute, getOpsV1PickupTaskListRoute, getOpsV1TrackingRoute } from '../pages/OpsDashboardV1Pages/routes';
import SideMenuV2 from './SideMenuV2';
import { withTranslation } from 'react-i18next';
import SwitchViewType from '../common/SwitchViewType';
import { API_PLAYGROUND_BASE_PATH } from '../common/persistentIframeRenderer/constants';
import { SHOW_DEV_TOOLS } from 'src/globals';
import { DownloadOutlined, EyeOutlined, HighlightOutlined, UploadOutlined, CloseOutlined, GlobalOutlined } from '@ant-design/icons';
import { AnalyticsAnalyzeIcon, AnalyticsIcon, AnalyticsOverviewIcon, BIIcon, BagIcon, BankDepositHistoryIcon, BankDepositIcon, CRMIcon, CallsOverviewIcon, ConsignmentDashboardIcon, ConsignmentIcon, ConsolidatedIcon, CoordinatorPortalIcon, CounterBookingIcon, CustomerActivityIcon, DevToolsIcon, FMLMIcon, FinanceOperationIcon, IntegrationLogsIcon, APIPlaygroundIcon, IntegrationsIcon, InvoiceIcon, ManagePISIcon, MiddleMileIcon, MiddleMileOverviewIcon, MyCallsIcon, OptimizationRequestIcon, OrderMgmtIcon, PISSummaryIcon, RemittanceIcon, RiderIcon, RiderManagementODIcon, RiderVehicleIcon, RosterManagementODIcon, RoutingPlaygroundIcon, SetupIcon, ShelfMgmtIcon, ShipsyAIIcon, StoreIcon, SupportTeamPortalIcon, SupportToolsIcon, TerritoryOptimizationIcon, ThirdPartyIcon, TrackingIcon, TransactionHistoryIcon, ThirdPartyTransactionHistoryIcon, TripIcon, TripManagerIcon, VehicleIcon, DeliveryIcon, PickupIcon, PrintLabelRequestIcon, RequestsIcon, VehicleAttendanceIcon, AttendanceIcon, KMReadingIcon, RiderReconnIcon, SupplyIcon, PriceCalculatorIcon, ConsolidatedPlanningIcon, GeocodingPlaygroundIcon, VendorPayoutIcon, ThirdPartyReconciliationIcon, LIACoPilotIcon, LIAIcon, HomeIcon, LiveDashboardIcon, TicketIcon } from '../common/icons/NavbarMenuIcons';
import { SlotManagementIcon } from '../../assets/images/SlotManagement';
import CollapsibleItemGroup from './CollapsibleItemGroup';
import { IntegrationMarketplaceIcon } from '../common/icons/IntegrationMarketplaceIcon';
import { setupSideNavConfig } from '../pages/OpsDashboard/Setup/setupSideNavConfig';
import { getFullRouteForId, shouldAllowSetupRoute } from '../pages/OpsDashboard/Setup/routes';
import { SetupSubmenu, SetupSubmenuOnDemand, menuUrlReverseMapping, setupMenuDetailsNew } from './SideMenu.Constants';
import setupNavMenuItems from './SideMenu.Constants';
import { getConsignmentsSubmenu } from './utils';
import { ManageBagNavIcon } from '../common/icons/opsDashboard/ManageBagNavIcon';
import { OrderManagementIcon } from '../common/icons/OrderManagementIcon';
import { ReportingIcon } from '../common/icons/opsDashboard/ReportingIcon';
import { DispatchManagementIcon } from '../common/icons/opsDashboard/DispatchManagementIcon';
import { CODSettlementIcon } from '../common/icons/opsDashboard/CODSettlementIcon';
import { HelpdeskNotificationIcon } from '../common/icons/opsDashboard/HelpdeskNotificationIcon';
import { getPriceCalculatorRoute } from '../pages/PriceCalculator/routes';
import { isNativeApp } from 'src/utils/utils';
const SubMenu = Menu.SubMenu;
import { ANALYTICS_BASE_PATH, LIA_CO_PILOT_BASE_PATH } from 'src/components/pages/queryBuilder/routePath';
import { TICKET_MANAGEMENT_BASE_PATH } from 'src/components/pages/TicketManagement/routerPath';
import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';
import { getValletTransactionsRoute } from '../pages/ValletTransactions/routes';

const styles = (theme: ThemeType): CssType => ({
    drawer: {
        color: theme.colors.surfaceBg,
        left: '0px',
        right: 'auto',
        width: '516px !important',
        '& .ant-drawer-body': {
            background: theme.colors.globalHeader,
            color: theme.colors.textOnDarkBg,
            padding: '0px !important',
            height: 'auto !important',
            '&>.ant-menu': {
                height: 'auto !important',
                background: theme.colors.globalHeader,
                color: theme.colors.textOnDarkBg
                // minHeight: '100vh !important',
            },
        },
    },
    mainMenu: {
        fontFamily: theme.fonts.fontFamily,
        fontSize: theme.sizes.subHeading,
        color: theme.colors.textOnDarkBg,
        background: theme.colors.globalHeader,
        '& .ant-menu-item': {
            color: theme.colors.textOnDarkBg,
            fontWeight: 600,
            fontSize: 14, //using 15 instead of 16 given as discussed
            height: 41, //using 41 to test this out
            lineHeight: 16,
            '& .ant-menu-item-icon, .anticon': {
                '& > span > svg, svg': {
                    '& path': {
                        stroke: `${theme.colors.tertiaryColor} !important`,
                    }
                },
            },
        },
        '& .ant-menu-item-selected': {
            backgroundColor: `${theme.colors.hyperlink} !important`,
            color: theme.colors.selectionOnDarkBg,
            border: 'none !important',
            // boxShadow: 'none !important',
        },
        '& .ant-menu-item:hover': {
            backgroundColor: `${theme.colors.hyperlink}4D !important`, // Add the desired opacity directly to the hex color
        },
        '& .ant-menu-item-icon': {
            height: 20,
            width: 20,
            color: `${theme.colors.tertiaryColor} !important`,
        },
        // '& .ant-menu-item:hover::after, .ant-menu-item-selected::after': {
            border: 'none !important',
        //     boxShadow: 'none !important',
        //     transition: 'none !important',
        // },
    },
    mobileMenu: {
        fontFamily: theme.fonts.fontFamily,
        color: theme.colors.textOnDarkBg,
        background: theme.colors.globalHeader,
        '& .ant-menu-item': {
            color: theme.colors.textOnDarkBg,
            fontWeight: 700,
            fontSize: '16px',
            height: '56px',
            lineHeight: '22px',
            '& .ant-menu-item-icon, .anticon': {
                '& > span > svg, svg': {
                    '& path': {
                        stroke: `${theme.colors.tertiaryColor} !important`,
                    },
                },
            },
        },
        '& .ant-menu-item-selected': {
            backgroundColor: `${theme.colors.hyperlink} !important`,
            border: 'none !important',
        },
        '& .ant-menu-item-icon': {
            height: 20,
            width: 20,
        },
    },
    mobileSubMenu: {
        '& .ant-menu-title-content': {
            color: theme.colors.textOnDarkBg,
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '22px',
        },
        '& .ant-menu-sub': {
            background: theme.colors.globalHeader,
        },
    },
    mobileDrawer: {
        color: theme.colors.surfaceBg,
        left: '0px',
        right: 'auto',
        '& .ant-drawer-body': {
            background: theme.colors.globalHeader,
            color: theme.colors.textOnDarkBg,
            padding: '0px !important',
            height: 'auto !important',
            '&>.ant-menu': {
                height: 'auto !important',
                background: theme.colors.globalHeader,
                color: theme.colors.textOnDarkBg,
            },
        },
        '& .ant-drawer-content': {
            backgroundColor: 'transparent',
        },
    },
    submenu: {
        background: theme.colors.globalHeader,
        fontSize: theme.sizes.subHeading,
        fontFamily: theme.fonts.fontFamily,
        color: theme.colors.textOnDarkBg,
        '& .ant-menu-item': {
            background: theme.colors.globalHeader,
            color: theme.colors.textOnDarkBg,
            fontWeight: 600,
            '& > span > svg, svg': {
                '& path': {
                    stroke: `${theme.colors.tertiaryColor} !important`,
                }
            },
            '& .ant-menu-item-icon, .anticon': {
                color: `${theme.colors.tertiaryColor} !important`,
                '& path': {
                    stroke: `${theme.colors.tertiaryColor} !important`,
                },
                '& > span > svg, svg': {
                    '& path': {
                        stroke: `${theme.colors.tertiaryColor} !important`,
                    }
                },
            },
        },
        '& .ant-menu-item:hover': {
            backgroundColor: `${theme.colors.hyperlink}4D !important`,
            color: `${theme.colors.tertiaryColor} !important`,
        },
        '& .ant-menu-submenu, .ant-menu': {
            fontWeight: 600,
            fontSize: '12px !important',
            color: theme.colors.textOnDarkBg,
            background: theme.colors.globalHeader,
        },
        '& .ant-menu-submenu:hover': {
            color: `${theme.colors.textOnDarkBg} !important`,
            backgroundColor: `${theme.colors.hyperlink}4D !important`,
        },
        '& .ant-menu-submenu-title > .ant-menu-title-content': {
            color: `${theme.colors.textOnDarkBg} !important`,
        },


        '& .ant-menu-item-selected': {
            backgroundColor: `${theme.colors.hyperlink} !important`,
            color: theme.colors.selectionOnDarkBg,
            border: 'none !important',
            // boxShadow: 'none !important',
        },
        '& span>svg': {
            color: theme.colors.tertiaryColor,
            stroke: `${theme.colors.tertiaryColor} !important`,
        },
        '& .ant-menu-item-icon, .ant-menu-submenu-icon': {
            '& span>svg': {
                color: theme.colors.tertiaryColor,
                stroke: `${theme.colors.tertiaryColor} !important`,
            },
            color: theme.colors.tertiaryColor,
            '& path': {
                stroke: `${theme.colors.tertiaryColor} !important`,
            },
            height: 16,
            width: 16,
        },
        '& .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left': {
            border: 'none !important',
        },
        '& .ant-menu-submenu-title': {
            color: theme.colors.textOnDarkBg,
        },
        '& .ant-menu-submenu-arrow': {
            color: theme.colors.textOnDarkBg,
            '& :hover': {
                color: theme.colors.textOnDarkBg,
            }
        },
        '& .ant-menu-item a::before': {
            '& path': {
                stroke: `${theme.colors.tertiaryColor} !important`,
            },
        },
    },
    menuGroup: {
        color: theme.colors.textOnDarkBg,
        height: 56,
        fontSize: 16,
    },
    navMenuSide: {
        color: theme.colors.textOnDarkBg,
        // top: `${NAVBAR_HEIGHT}px !important`,
        // // height: `calc( 100vh - ${NAVBAR_HEIGHT}px) !important`,
        // width: '256px !important',
        // background: 'white',
    },
    menuItem: {
        color: theme.colors.textOnDarkBg,
    },
    alertsMenuMobile: {
        display: 'none',
    },
    [`@media (max-width: ${MOBILE_BREAK_POINT}px)`]: {
        hideLayout: {
            display: 'none',
        },
        alertsMenuDesktop: {
            display: 'none',
        },
        alertsMenuMobile: {
            display: 'initial',
        },
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '60px !important',
        '&:hover': {
            cursor: 'unset',
        },
    },
    borderTop: {
        borderTop: `1px solid ${theme.colors.primaryColor}`,
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        height: 'auto',
    },
    flexItemMenu: {
        width: 256,
        height: '100%',
        minHeight: 'min(80vh, 600px)',
    },
    flexItemSubmenu: {
        overflowY: 'auto',
        overflowX: 'hidden',
        width: 256,
        border: 'none !important',
        '& .ant-menu-inline': {
            border: 'none !important',
        }
    },
    subMenuIcon: {
    },
    separator: {
        width: 1,
        margin: '0 1px',
        background: `${theme.colors.surfaceBg}33`,
    },
    searchShortcut: {
        textAlign: 'center',
        height: 20,
        paddingTop: 4,
        background: theme.colors.globalHeader,
        boxShadow: '8px 8px 12px rgba(0, 0, 0, 0.120957)',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 10,
        // lineHeight: 10,
        color: theme.colors.textOnDarkBg,
    },
    oldTag:{
        color: theme.colors.tertiaryText,
        marginLeft: '10%',
        padding: '0% 7%',
        fontSize: 10,
    },
    newTag:{
        color: theme.colors.tertiaryText,
        marginLeft: '10%',
        fontSize: 10,
    },
});
const MenuItem = Menu.Item;

class SideMenu extends React.PureComponent<any, any> {
    menuRef: React.RefObject<HTMLDivElement>;
    constructor(props) {
        super(props);
        const path = location?.pathname;
        let selectedItem: any = menuUrlReverseMapping[path]
            || menuUrlReverseMapping[path.slice(0, path.lastIndexOf('/'))];
        // console.log('path menuUrlReverseMapping lat', menuUrlReverseMapping[path]);
        // console.log('path menuUrlReverseMapping[path.slice]', menuUrlReverseMapping[path.slice(0, path.lastIndexOf('/'))]);
        // console.log('path selected', selectedItem);

        const parts = location?.pathname?.split('/');
        if ((!selectedItem) && parts.length > 2) {
            const newPath = '/' + parts.slice(2).join('/');
            selectedItem = menuUrlReverseMapping[newPath]
                || menuUrlReverseMapping[newPath.slice(0, newPath.lastIndexOf('/'))];
        }
        this.state = {
            // currentPath: path,
            navLogo: LocalStorageService.getRaw(LocalStorageKeys.__LOGO_URL__),
            collapseMenu: true,
            bodyHeight: 0,
            menuKeySelected: selectedItem?.menuKey || 'consignmentSubmenu',
            subMenuKeySelected: selectedItem?.subMenuKey,
            subSubMenuKeySelected: selectedItem?.subSubMenuKey,
            submenuExists: true,
            isMenuSizeBig: false,
        };
        this.menuRef = React.createRef<HTMLDivElement>();
    }

    handleResize = () => {
        if (this.menuRef?.current?.clientHeight > 0.90 * window.innerHeight) {
            this.setState({ isMenuSizeBig: true });
        } else if (this.state.isMenuSizeBig) {
            this.setState({ isMenuSizeBig: false });
        }
    };

    loadSelectedMenuFromURL = () => {
        const path = location?.pathname;
        let selectedItem: any = menuUrlReverseMapping[path]
            || menuUrlReverseMapping[path.slice(0, path.lastIndexOf('/'))];

        const parts = location?.pathname?.split('/');
        if (!selectedItem && parts.length > 2) {
            const newPath = '/' + parts.slice(2).join('/');
            selectedItem = menuUrlReverseMapping[newPath]
                || menuUrlReverseMapping[newPath.slice(0, newPath.lastIndexOf('/'))];
        }
        if(selectedItem && Object.keys(selectedItem || []).length && this.state.currentPath !== path) {
            this.setState({
                currentPath: path,
                menuKeySelected: selectedItem?.menuKey,
                subMenuKeySelected: selectedItem?.subMenuKey,
                subSubMenuKeySelected: selectedItem?.subSubMenuKey,
            });
        }
    };

    componentDidMount = () => {
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('zoom', this.handleResize);
        this.loadSelectedMenuFromURL();
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('zoom', this.handleResize);
    };

    componentDidUpdate = () => {
        if (this.state.bodyHeight !== this.menuRef?.current?.clientHeight) {
            this.setState({ bodyHeight: this.menuRef?.current?.clientHeight + 24 });
        }
        if (this.menuRef?.current?.clientHeight > 0.90 * window.innerHeight && !this.state.isMenuSizeBig) {
            this.setState({ isMenuSizeBig: true });
        }
    };

    closeDrawer = ({ key }) => {
        // Close if not logo
        if (key === 'logo') {
            return;
        }
        this.props.closeSideMenu();
    };

    onMenuClick = (event) => {
        const { history } = this.props;
        // if (event.key === 'expressAnalytics') {
        //     this.setState({ submenuExists: false });
        // } else if (!this.state.submenuExists) {
        //     this.setState({ submenuExists: true});
        // }
        this.setState({ menuKeySelected: event.key });
        if (event.key === 'landingPageHome') {
            this.props.closeSideMenu();
            history.push('/home');
        }
    };

    render() {
        const { modules } = this.state;
        const { classes, open, closeSideMenu, showRetailOptions, showLtlOptions,
            showAnalytics, showOptimizationRequests, showRetailOverviewDashboard,
            showMetrics, showBankDeposit,
            showThirdParty,
            viewType,
            showOpsV1DeliveryTaskList,
            showLogisticsExpressOptions,
            showCRM,
            showRemittanceCrm,
            showHybridView,
            i18n,
            theme,
            showCounterBooking,
            showV1RemittanceDashboard,
            showV2RemittanceDashboard,
            showCallsOverview,
            showMyCalls,
            showSupplementoryServices,
            showViewTypeSwitch,
            showGenericSideMenu,
            masterData,
            showOndemandOptions,
            showVehicleRequest,
            viewTypeForSwitchView,
            location,
            employee_pages,
            t,
            employeePermissions,
            showCarrierPartner,
        } = this.props;
        const applicationTypeCRM= (applicationType === 'crm') ? true : false;
        const applicationTypeOps = (applicationType === 'ops') ? true : false;
        const showTmsOptions = viewType === 'tms' ? true : false;
        const checkUsingNewV2 = (showLogisticsExpressOptions
            || showHybridView
            || showGenericSideMenu
        ) && applicationTypeOps;
        const ondemandViewTypes = ['ondemand', 'on_demand_cod'];
        const checkUsingOndemand = showOndemandOptions &&
          (!viewTypeForSwitchView || ondemandViewTypes.includes(viewTypeForSwitchView));
        if (checkUsingOndemand && this?.state?.menuKeySelected === 'consignmentSubmenu') {
            this.setState({menuKeySelected: 'ordersSubmenu'});
        }

        this.loadSelectedMenuFromURL();

        const displayMenuItem = (key: string): boolean => {
            if (checkUsingOndemand) {
                switch (key) {
                    case 'orderManagament':
                    case 'reporting':
                    case 'dispatchManagament':
                    case 'analytics':
                    case 'dispatchManagament':
                    case 'apiPlayground':
                        return true;
                    case 'integrationMarketplace':
                        return employeePermissions?.show_integration_marketplace;
                    case 'queryBuilder':
                        if (masterData?.show_query_builder_crm) return true;
                        return false;
                    case 'copilot':
                        if (masterData?.show_incident_management_copilot) return true;
                        return false;
                    case 'devtools':
                        if (SHOW_DEV_TOOLS) return true;
                        return false;
                    case 'helpdeskNotifications':
                        if (masterData?.ops_dashboard_config.parts_to_show?.show_ondemand_helpdesk) return true;
                        return false;
                    case 'codSettlement':
                        if (masterData?.show_worker_credit_module && !masterData?.cash_and_order_recon_v2) return true;
                        return false;
                    case 'bankDeposit':
                        if (masterData?.bank_deposit_read && !masterData?.cash_and_order_recon_v2) return true;
                        return false;
                    case 'bankTransaction':
                        if (masterData?.bank_deposit_read && !masterData?.cash_and_order_recon_v2) return true;
                        return false;
                    case 'territoryOptimization':
                        if (masterData.ops_dashboard_config?.parts_to_show
                            ?.show_territory_optimisation_parts) return true;
                        return false;
                    case 'routingPlayground':
                        if (masterData.ops_dashboard_config?.parts_to_show
                            ?.show_routing_playground_parts) return true;
                        return false;
                    case 'dashboards':
                        return employee_pages?.length;
                    case 'erpSync':
                        if (checkUsingNewV2 && employeePermissions?.show_integration_logs) return true;
                        return false;
                    case 'slot_management':
                        if(masterData?.workerSlotManagementConfig) return true;
                        return false;
                    case 'transaction':
                        if (masterData?.cash_and_order_recon_v2 && masterData.rider_recon_read) {
                            return true;
                        }
                        return false;
                    case 'riderReconciliation':
                        if (masterData?.cash_and_order_recon_v2 && masterData.rider_recon_read) {
                            return true;
                        }
                        return false;
                    // case 'riderManagementOD':
                    //     return true;
                    // case 'rosterManagementOD':
                    //     if (masterData.show_roster_management_ondemand) return true;
                    //     return false;
                    default:
                        return false;
                }
            }
            switch (key) {
                case 'dashboards':
                    return employee_pages?.length;
                case 'integrationMarketplace':
                    return employeePermissions?.show_integration_marketplace;
                case 'consignments':
                    if (checkUsingNewV2) {
                        return true;
                    }
                    if (!showLtlOptions && !showTmsOptions) {
                        return true;
                    }
                    return false;
                case 'tmsDownloadRequests':
                    if (applicationTypeOps && (showTmsOptions)) {
                        return true;
                    }
                    return false;
                case 'downloads':
                    if (checkUsingNewV2 && employeePermissions?.show_download_requests) {
                        return true;
                    }
                    if ((applicationTypeCRM || showRetailOptions || showLogisticsExpressOptions ||
                        showLtlOptions) && employeePermissions?.show_download_requests) {
                        return true;
                    }
                    return false;
                    case 'uploads':
                        if (checkUsingNewV2 && masterData.ops_dashboard_config?.parts_to_show
                            ?.enable_async_consignment_softdata_upload) {
                            return true;
                        }
                        return false;
                case 'crmInOps':
                    if (checkUsingNewV2) {
                        if (showCRM) return true;
                        return false;
                    }
                    if ((showLogisticsExpressOptions || showRetailOptions || showLtlOptions)
                        && showCRM) {
                        return true;
                    }
                    return false;
                case 'customerActivity':
                    if (checkUsingNewV2 && employeePermissions?.show_customer_activity) {
                        return true;
                    }
                    if (applicationTypeOps && (showLtlOptions || showRetailOptions) && employeePermissions?.show_customer_activity) {
                        return true;
                    }
                    return false;
                case 'counterBooking':
                    if (checkUsingNewV2) {
                        if (masterData.single_consignment_booking_config?.show_on_ops_v2) return true;
                        return false;
                    }
                    if (applicationTypeOps && showCounterBooking) {
                        return true;
                    }
                    return false;
                case 'printLabelRequests':
                    if (checkUsingNewV2) {
                        if (!masterData.show_logistics_hybrid_express_view)return true;
                        return false;
                    }
                    if (applicationTypeOps && showRetailOptions && !showTmsOptions) {
                        return true;
                    }
                    return false;
                case 'coordinator_portal':
                    if (checkUsingNewV2 && employeePermissions?.show_coordinator_portal) {
                        return true;
                    }
                    return false;
                case 'retailStoreView':
                    if (!checkUsingNewV2 && !HIDE_RETAIL_STORE_HUB_CHANGES && applicationTypeOps && showRetailOptions) {
                        return true;
                    }
                    return false;
                case 'opsv1pickupTaskList':
                    if (checkUsingNewV2 && masterData.show_logistics_hybrid_express_view) {
                        return true;
                    }
                    return false;
                case 'opsv1deliverytasklist':
                    if (checkUsingNewV2) {
                        if (masterData.show_logistics_hybrid_express_view) return true;
                        return false;
                    }
                    if (applicationTypeOps && showOpsV1DeliveryTaskList) {
                        return true;
                    }
                    return false;
                case 'tripmanager':
                    if (checkUsingNewV2) {
                        if (masterData.show_trip_manager_hybrid_view ||
                        !masterData.show_logistics_hybrid_express_view) return true;
                        return false;
                    }
                    if ((applicationTypeOps &&
                        (showRetailOptions || showLogisticsExpressOptions) && !showTmsOptions)) {
                        return true;
                    }
                    return false;
                case 'consolidated_planning':
                    if (checkUsingNewV2 && masterData.show_consolidated_planning) {
                        return true;
                    }
                    return false;
                case 'live_dashboard':
                    if (checkUsingNewV2 && masterData.show_live_dashboard) {
                        return true;
                    }
                    return false;
                case 'tracking':
                    if (checkUsingNewV2) {
                        if (masterData.show_trip_manager_hybrid_view ||
                        !masterData.show_logistics_hybrid_express_view) return true;
                        return false;
                    }
                    if (applicationTypeOps && (showRetailOptions || showLogisticsExpressOptions)) {
                        return true;
                    }
                    return false;
                case 'retailOptimizationRequests':
                    if (checkUsingNewV2) {
                        if (masterData.show_trip_manager_hybrid_view ||
                        !masterData.show_logistics_hybrid_express_view) return true;
                        return false;
                    }
                    if (applicationTypeOps && (showRetailOptions || showLogisticsExpressOptions)
                        && showOptimizationRequests) {
                        return true;
                    }
                    return false;
                case 'custom_trip_creation_requests':
                    if (checkUsingNewV2) {
                        if (masterData.ops_dashboard_config?.parts_to_show
                            ?.enable_manual_trip_creation ||
                          masterData.ops_dashboard_config?.parts_to_show
                            ?.courier_partner_recon_config?.enable_courier_partner_recon) return true;
                        return false;
                    }
                    return false;
                case 'riderReconciliation':
                    if (checkUsingNewV2) {
                        if(masterData.rider_recon_read)return true;
                        return false;
                    }
                    if (viewType === 'logistics') {
                        return true;
                    }
                    return false;
                case 'carrier_reconciliation':
                    if (checkUsingNewV2) {
                        if(masterData.ops_dashboard_config?.parts_to_show
                            ?.courier_partner_recon_config?.enable_courier_partner_recon &&
                          masterData.third_party_recon_read)return true;
                    }
                    return false;
                case 'tmsVehicle':
                    if (checkUsingNewV2) {
                        return true;
                    }
                    if (applicationTypeOps && (showTmsOptions || showLogisticsExpressOptions)) {
                        return true;
                    }
                    return false;
                case 'vehicle_attendance':
                    if (checkUsingNewV2) {
                        if ( masterData.ops_dashboard_config.parts_to_show.show_vehicle_attendance) return true;
                    }
                    return false;
                case 'VehicleRequest':
                    if (showVehicleRequest) {
                        return true;
                    }
                    return false;
                case 'rider':
                case 'tracking_v1':
                case 'km_reading':
                case 'attendance':
                    if (checkUsingNewV2) {
                        if (masterData.show_logistics_hybrid_express_view) return true;
                    }
                    return false;
                case 'tmsBag':
                case 'tmsIST':
                    if (checkUsingNewV2) {
                        if (masterData.ops_dashboard_config.parts_to_show.show_line_haul) return true;
                        return false;
                    }
                    if (applicationTypeOps && (showTmsOptions || showLogisticsExpressOptions)) {
                        return true;
                    }
                    return false;
                case 'tmsOverview':
                    if (checkUsingNewV2 && employeePermissions?.show_midmile_overview) {
                        if (masterData.ops_dashboard_config.parts_to_show.show_line_haul) return true;
                        return false;
                    }
                    if (applicationTypeOps && (showTmsOptions || showLogisticsExpressOptions)
                        && employeePermissions?.show_midmile_overview) {
                        return true;
                    }
                    return false;
                case 'supplementoryServices':
                    if (showSupplementoryServices) {
                        return true;
                    }
                    return false;
                case 'shelf':
                    if (checkUsingNewV2) {
                        return true;
                    }
                    if (viewType === 'logistics' || viewType === 'logistics_express') {
                        return true;
                    }
                    return false;
                case 'transaction':
                    if (checkUsingNewV2 && masterData.rider_recon_read) {
                        return true;
                    }
                    if (applicationTypeCRM) {
                        return true;
                    }
                    return false;
                case 'carrier_transactions':
                    if (checkUsingNewV2 && masterData.ops_dashboard_config?.parts_to_show
                        ?.courier_partner_recon_config?.enable_courier_partner_recon &&
                      masterData.third_party_recon_read) {
                        return true;
                    }
                    return false;
                case 'bankDeposit':
                    if (checkUsingNewV2 && masterData.bank_deposit_read) {
                        return true;
                    }
                    if (applicationTypeCRM && showBankDeposit) {
                        return true;
                    }
                    return false;
                case 'bankTransaction':
                    if (checkUsingNewV2 && masterData.bank_deposit_read) {
                        return true;
                    }
                    if (applicationTypeCRM && showBankDeposit) {
                        return true;
                    }
                    return false;
                case 'cashRemittance':
                    if (checkUsingNewV2 && masterData.show_hub_operations_module) {
                        return true;
                    }
                    if (!checkUsingNewV2 && applicationTypeCRM &&
                        (showV1RemittanceDashboard || showV2RemittanceDashboard)
                    ) {
                        return true;
                    }
                    return false;
                case 'invoices':
                    if (checkUsingNewV2 &&  masterData.show_hub_operations_module
                        && (masterData.ops_dashboard_config.parts_to_show
                        ?.setup_permission_obj?.rate_masters?.invoice?.read
                        && masterData.ops_dashboard_config.parts_to_show?.setup_rate_master)) {
                        return true;
                    }
                    if (!checkUsingNewV2 && viewType === 'logistics') {
                        return true;
                    }
                    return false;
                case 'supply_request':
                    if (checkUsingNewV2 && masterData?.show_supply_request) {
                        return true;
                    }
                    return false;
                case 'PISSummary':
                case 'managePIS':
                    if (checkUsingNewV2 && masterData.show_pay_in_slip_module) {
                        return true;
                    }
                    if (!checkUsingNewV2 && applicationTypeOps && showLogisticsExpressOptions) {
                        return true;
                    }
                    return false;
                case 'vendorPayout':
                    if (checkUsingNewV2 && masterData.vendor_payout_read) {
                        return true;
                    }
                    if (!checkUsingNewV2 && applicationTypeOps && showLogisticsExpressOptions) {
                        return true;
                    }
                    return false;
                case 'valletTransactions':
                    return true;
                case 'thirdparty':
                    if (checkUsingNewV2) {
                        if (masterData.third_party_read)return true;
                        return false;
                    }
                    if (applicationTypeOps && showThirdParty) {
                        return true;
                    }
                    return false;
                case 'domesticTracking':
                    if (checkUsingNewV2) {
                        if (masterData.third_party_read)return true;
                        return false;
                    }
                    if (applicationTypeOps && (showLtlOptions || showThirdParty)) {
                        return true;
                    }
                    return false;
                case 'priceCalculator':
                    if (checkUsingNewV2 && masterData?.enable_price_calculator_module) {
                        return true;
                    }
                    return false;
                case 'expressAnalytics':
                    if ((!checkUsingNewV2) && (showLogisticsExpressOptions
                        || (applicationTypeOps && showRetailOptions && showRetailOverviewDashboard)
                        )) {
                        return true;
                    }
                    return false;
                case 'overview_analytics':
                    if (checkUsingNewV2 && masterData.ops_dashboard_config?.parts_to_show
                        ?.express_analytics_overview) {
                        return true;
                    }
                    return false;
                case 'ltlAnalytics':
                    if (!checkUsingNewV2 && applicationTypeOps && showLtlOptions) {
                        return true;
                    }
                    return false;
                case 'analyze':
                    if (checkUsingNewV2 && masterData.ops_dashboard_config?.parts_to_show?.analyze_application) {
                        return true;
                    }
                    return false;
                case 'queryBuilder':
                    if (checkUsingNewV2 && masterData?.show_query_builder_crm) {
                        return true;
                    }
                    return false;
                case 'generic-download-requests':
                    if (checkUsingNewV2 && employeePermissions?.show_download_requests) {
                        return true;
                    }
                    return false;
                case 'erpSync':
                    if (checkUsingNewV2 && employeePermissions?.show_integration_logs) return true;
                    if (applicationTypeOps && (showRetailOptions || showLogisticsExpressOptions)
                        && employeePermissions?.show_integration_logs) {
                        return true;
                    }
                    return false;
                case 'routingPlayground':
                    if (checkUsingNewV2 && masterData.ops_dashboard_config?.parts_to_show
                        ?.show_routing_playground_parts) {
                        return true;
                    }
                    return false;
                case 'territoryOptimization':
                    if (checkUsingNewV2 && masterData.ops_dashboard_config?.parts_to_show
                        ?.show_territory_optimisation_parts) {
                        return true;
                    }
                    return false;
                case 'geocodingPlayground':
                    if (masterData.ops_dashboard_config?.parts_to_show?.show_geocoding_playground) {
                        return true;
                    }
                    return false;
                case 'retailSupportPortal':
                    if (!checkUsingNewV2 && !HIDE_RETAIL_CUSTOMER_SUPPORT_PORTAL
                        && applicationTypeOps && showRetailOptions) {
                        return true;
                    }
                    return false;
                case 'devtools':
                    if (SHOW_DEV_TOOLS) {
                        return true;
                    }
                    return false;
                case 'copilot':
                    if (masterData?.show_incident_management_copilot) return true;
                    return false;
                case 'landingPage':
                    if (masterData?.showDashboardGroupLandingPage) return true;
                    return false;
                case 'slot_management':
                    if(masterData?.workerSlotManagementConfig) return true;
                    return false;
                case 'planeIntegration':
                  if(masterData?.show_plane || true) return true;
                  return false;
                case 'network':
                    return true;
                default:
                    return false;
            }
        };

        const handleSubMenuToggle = (title: string) => {
            if (this.state.subMenuKeySelected !== title) {
                this.setState({subMenuKeySelected: title});
            } else {
                this.setState({subMenuKeySelected: null});
            }
        };

        const getSelectedKeys = () => {
            const pathFull = location.pathname;
            const path = pathFull.slice(0, pathFull.lastIndexOf('/'));
            return menuUrlReverseMapping[pathFull]?.subSubMenuKey
                || menuUrlReverseMapping[pathFull]?.subMenuKey
                || menuUrlReverseMapping[path]?.subSubMenuKey
                || menuUrlReverseMapping[path]?.subMenuKey;
        };
        const getSelectedMenuKeys = () => {
            const pathFull = location.pathname;
            const path = pathFull.slice(0, pathFull.lastIndexOf('/'));
            return menuUrlReverseMapping[pathFull]?.menuKey
                || menuUrlReverseMapping[path]?.menuKey;
        };

        const showSetupSubmenu = (subItems: any[]) => {
            console.log('subItems', subItems);
            let show = false;
            for(const childTitle of subItems) {
                if (show) return show;
                if (!setupNavMenuItems[childTitle]) {
                    if (!setupMenuDetailsNew[childTitle]) {
                        continue;
                    }
                    show = !setupMenuDetailsNew[childTitle]?.isAllowed
                        || setupMenuDetailsNew[childTitle]?.isAllowed(masterData);
                }
                show = shouldAllowSetupRoute(masterData, setupNavMenuItems[childTitle]?.routeId);
            }
            return show;
        };

        const submenu = (selectedKey: string) => {
            switch (selectedKey) {
                case 'dashboardsSubMenu':
                    return <Menu
                        style={{ width: 256 }}
                        mode="inline"
                        selectable={false}
                        onClick={this.closeDrawer}
                        triggerSubMenuAction="hover"
                        className={classes.submenu}
                        selectedKeys={getSelectedKeys()}
                        theme="light">
                            {
                                employee_pages?.map((page) => {
                                    return (
                                        <MenuItem key={page.id} icon={<OrderManagementIcon />}>
                                            <Link to={getPageBuilderDashboardRoute(page.id)}>
                                                <span className={classes.menuItem}>
                                                    {t(page.name)}
                                                </span>
                                            </Link>
                                        </MenuItem>
                                    );
                                })
                            }
                    </Menu>;
                case 'consignmentSubmenu':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            selectable={false}
                            onClick={this.closeDrawer}
                            triggerSubMenuAction="hover"
                            className={classes.submenu}
                            // inlineCollapsed={this.state.collapseMenu}
                            // onMouseEnter={() => this.setState({collapseMenu: false})}
                            // onMouseLeave={() => this.setState({collapseMenu: true})}
                            // defaultOpenKeys={['consignmentSubmenu']}
                            // openKeys={[this.state.subMenuKeySelected]}
                            // defaultSelectedKeys={[(this.state.subSubMenuKeySelected || this.state.subMenuKeySelected)]}
                            selectedKeys={getSelectedKeys()}
                            theme="light">
                            { displayMenuItem('consignments') ?
                                <MenuItem key="consignments" icon={<ConsignmentDashboardIcon />} >
                                    <Link to="/consignments">
                                        <span className={classes.menuItem}>
                                            {t('consignments_dashboard')}
                                        </span>
                                    </Link>
                            </MenuItem> : null}
                            { displayMenuItem('tmsBag') ?
                                <MenuItem key="tmsBag" icon={<BagIcon />}>
                                    <Link to={getTmsBagRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('bags')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('tmsDownloadRequests') ?
                            <MenuItem key="tmsDownloadRequests" icon={<DownloadOutlined />}>
                                <Link to={getTmsDownloadRequestRoute()}>
                                    <span className={classes.menuItem}>
                                        {t('download_requests')}
                                    </span>
                                </Link>
                            </MenuItem> : null}
                            { displayMenuItem('downloads') ?
                            <MenuItem key="downloads" icon={<DownloadOutlined />}>
                                <Link to={getDownloadsRoute()}>
                                    <span className={classes.menuItem}>
                                    {t('download_requests')}
                                    </span>
                                </Link>
                            </MenuItem> : null}
                                { displayMenuItem('uploads') ?
                                <MenuItem key="uploads" icon={<UploadOutlined />}>
                                    <Link to={getConsignmentUploadRequestRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('upload_requests')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('coordinator_portal') ?
                            <MenuItem key="coordinator_portal" icon={<CoordinatorPortalIcon />}>
                                <Link to={getOpsV1CoordinatorPortalRoute()}>
                                    <span className={classes.menuItem}>
                                        {t('coordinator_portal')}
                                    </span>
                                </Link>
                            </MenuItem> : null}
                            { displayMenuItem('customerActivity') ?
                            <MenuItem key="customerActivity" icon={<CustomerActivityIcon />}>
                                <Link to={getDomesticCustomerActivityRoute()}>
                                    <span className={classes.menuItem}>
                                        {t('customer_activity')}
                                    </span>
                                </Link>
                            </MenuItem> : null}
                            { displayMenuItem('counterBooking') ?
                                <MenuItem key="counterBooking" icon={<CounterBookingIcon />}>
                                    <Link to="/counter-booking">
                                        <span className={classes.menuItem}>
                                            {t('counter_booking')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('printLabelRequests') ?
                            <MenuItem key="printLabelRequests" icon={<PrintLabelRequestIcon />}>
                                <Link to={getPrintLabelRequestRoute()}>
                                    <span className={classes.menuItem}>
                                        {t('print_label_requests')}
                                    </span>
                                </Link>
                            </MenuItem> : null}
                            { displayMenuItem('crmInOps') || showCallsOverview || showMyCalls ?
                                <Menu.SubMenu key="CRM" title="CRM" icon={<CRMIcon />} onTitleClick={() => handleSubMenuToggle('CRM')}>
                                    { displayMenuItem('crmInOps') ?
                                    <MenuItem key="crmInOps" icon={<OrderManagementIcon />}>
                                        <Link to={getCRMInOpsRoute()}>
                                            {/* <span className={classes.menuItem}>CRM</span> */}
                                            <span className={classes.menuItem}>{t('consignments')}</span>
                                        </Link>
                                    </MenuItem> : null}
                                    {showCallsOverview ?
                                    <MenuItem key="showCallsOverview" icon={<CallsOverviewIcon />}>
                                        <Link to={getCallsOverviewRoute()}>
                                            <span className={classes.menuItem}>{t('calls_overview')}</span>
                                        </Link>
                                    </MenuItem> : null}
                                    {showMyCalls ?
                                    <MenuItem key="showMyCalls" icon={<MyCallsIcon />}>
                                        <Link to={getMyCallsRoute()}>
                                            <span className={classes.menuItem}>{t('my_calls')}</span>
                                        </Link>
                                    </MenuItem> : null}
                                </Menu.SubMenu>
                            : null }
                            { displayMenuItem('retailStoreView') ?
                                <MenuItem key="retailStoreView" icon={<StoreIcon />}>
                                    <Link to={getRetailStoreViewRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('store_consignments')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                        </Menu>
                    );
                case 'ordersSubmenu':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            selectable={false}
                            onClick={this.closeDrawer}
                            triggerSubMenuAction="hover"
                            className={classes.submenu}
                            // inlineCollapsed={this.state.collapseMenu}
                            // onMouseEnter={() => this.setState({collapseMenu: false})}
                            // onMouseLeave={() => this.setState({collapseMenu: true})}
                            // defaultOpenKeys={['consignmentSubmenu']}
                            openKeys={[this.state.openSubMenuKeys]}
                            selectedKeys={getSelectedKeys()}
                            theme="light"
                        >
                            <MenuItem key="reporting" icon={<ConsignmentDashboardIcon />}>
                                <Link to={getFoodDashboardReporting()}>
                                    <span className={classes.menuItem}>
                                        {t('orders_dashboard')}
                                    </span>
                                </Link>
                            </MenuItem>
                            <MenuItem key="orderManagament" icon={<OrderMgmtIcon />}>
                                <Link to={getFoodDashboardOrderManagement()}>
                                    <span className={classes.menuItem}>
                                        {t('order_management')}
                                    </span>
                                </Link>
                            </MenuItem>
                            <MenuItem key="dispatchManagament" icon={<DispatchManagementIcon />}>
                                <Link to={getFoodDashboardDispatchScreen()} target="_blank">
                                {/* <Link to={getFoodDashboardDispatchScreen()} target="_blank"> */}
                                    <span className={classes.menuItem}>
                                    {t('dispatch_management')}
                                    </span>
                                </Link>
                            </MenuItem>
                        </Menu>
                    );
                case 'FMandLMSubmenu':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            selectable={false}
                            onClick={this.closeDrawer}
                            className={classes.submenu}
                            triggerSubMenuAction="hover"
                            selectedKeys={getSelectedKeys()}
                            theme="light">
                            { displayMenuItem('opsv1pickupTaskList') ?
                                <MenuItem key="opsv1pickupTaskList" icon={<PickupIcon />}>
                                    <Link to={getOpsV1PickupTaskListRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('pickups')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('opsv1deliverytasklist') ?
                                <MenuItem key="opsv1deliverytasklist" icon={<DeliveryIcon />}>
                                    <Link to={getOpsV1DeliveryTaskListRoute()}>
                                        <span className={classes.menuItem}>
                                        {t('deliveries')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('tripmanager') ?
                                <MenuItem key="tripmanager" icon={<TripManagerIcon />}>
                                    <Link to={getRetailTripManagerRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('trip_manager')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('consolidated_planning') ?
                                <MenuItem key="consolidated_planning" icon={<ConsolidatedPlanningIcon />}>
                                    <Link to={'/retail/ConsolidatedPlanning'}>
                                        <span className={classes.menuItem}>
                                            {t('consolidated_planning')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('live_dashboard') ?
                            <MenuItem key="live_dashboard" icon={<LiveDashboardIcon />}>
                                <Link to={getLiveDashboardRoute()}>
                                    <span className={classes.menuItem}>
                                        {t('Live Dashboard')}
                                    </span>
                                </Link>
                            </MenuItem> : null}
                            { displayMenuItem('tracking') ?
                                <MenuItem key="tracking" icon={<TrackingIcon />}>
                                    <Link to={getRetailTrackingRoute()} target={'_blank'}>
                                        <span className={classes.menuItem}>
                                           { t('tracking')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('retailOptimizationRequests') ?
                                <MenuItem key="retailOptimizationRequests" icon={<OptimizationRequestIcon />}>
                                    <Link to={getAutoAllocateRequestsPageRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('optimisation_requests')}
                                        </span>
                                    </Link>
                                </MenuItem>
                                : null}
                            { displayMenuItem('custom_trip_creation_requests') ?
                                <MenuItem key="custom_trip_creation_requests" icon={<RequestsIcon />}>
                                    <Link to={getCustomTripRequestsRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('requests')}
                                        </span>
                                    </Link>
                                </MenuItem>
                                : null}
                            {displayMenuItem('slot_management') ?
                                <MenuItem key="slot_management" icon={< SlotManagementIcon/>}>
                                    <Link to={getSlotManagementBaseRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('slot_management')}
                                        </span>
                                    </Link>
                                </MenuItem>
                            : null}
                        </Menu>
                    );
                case 'RiderAndVehicleSubmenu':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            className={classes.submenu}
                            selectable={false}
                            onClick={this.closeDrawer}
                            triggerSubMenuAction="hover"
                            selectedKeys={getSelectedKeys()}
                            theme="light">
                            { displayMenuItem('rider') ?
                                <MenuItem key="rider" icon={<RiderIcon />}>
                                    <Link to={getOpsV1MediumListRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('rider')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('tracking_v1') ?
                                <MenuItem key="tracking_v1" icon={<EyeOutlined />}>
                                    <Link to={getOpsV1TrackingRoute()}>
                                        <span className={classes.menuItem}>
                                        {t('tracking')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('km_reading') ?
                                <MenuItem key="km_reading" icon={<KMReadingIcon />}>
                                    <Link to={getOpsV1KmReadingRoute()}>
                                        <span className={classes.menuItem}>
                                        {t('km_reading')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('attendance') ?
                                <MenuItem key="attendance" icon={<AttendanceIcon />}>
                                    <Link to={getOpsV1AttendanceRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('attendance')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('tmsVehicle') ?
                                <MenuItem key="tmsVehicle" icon={<VehicleIcon />}>
                                    <Link to={getTmsVehicleRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('vehicle')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('riderManagementOD') ?
                                <MenuItem key="riderManagementOD" icon={<RiderManagementODIcon />}>
                                    <Link to={getFoodDashboardRiderManagement()}>
                                        <span className={classes.menuItem}>
                                            {t('rider_management')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('rosterManagementOD') ?
                                <MenuItem key="rosterManagementOD" icon={<RosterManagementODIcon />}>
                                    <Link to={getFoodDashboardRosterManagement()}>
                                        <span className={classes.menuItem}>
                                            {t('roster_management')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('vehicle_attendance') ?
                                <MenuItem key="vehicle_attendance" icon={<VehicleAttendanceIcon />}>
                                    <Link to={getTmsVehicleAttendanceRoute()}>
                                        <span className={classes.menuItem}>
                                           {t('vehicle_attendance')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('VehicleRequest') ?
                                <MenuItem key="VehicleRequest" icon={<PISSummaryIcon />}>
                                    <Link to={getVehicleRequestRoute()}>
                                        <span className={classes.menuItem}>
                                            Vehicle Request
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                        </Menu>
                    );
                case 'MiddleMileSubMenu':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            className={classes.submenu}
                            selectable={false}
                            onClick={this.closeDrawer}
                            triggerSubMenuAction="hover"
                            selectedKeys={getSelectedKeys()}
                            theme="light">
                                { displayMenuItem('tmsOverview') ?
                                    <MenuItem key="tmsOverview" icon={<MiddleMileOverviewIcon />}>
                                        <Link to={getTmsOverviewRoute()}>
                                            <span className={classes.menuItem}>
                                                {t('overview')}
                                            </span>
                                        </Link>
                                    </MenuItem> : null}
                                { displayMenuItem('tmsIST') ?
                                <MenuItem key="tmsIST" icon={<TripIcon />}>
                                    <Link to={getTmsISTRoute()}>
                                        <span className={classes.menuItem}>
                                            {showLogisticsExpressOptions ? t('middle_mile_trips') : t('trips')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                                {/* { displayMenuItem('tmsBag') ?
                                    <MenuItem key="tmsBag" icon={<BagIcon />}>
                                        <Link to={getTmsBagRoute()}>
                                            <span className={classes.menuItem}>
                                                {t('bags')}
                                            </span>
                                        </Link>
                                    </MenuItem> : null} */}
                                { displayMenuItem('shelf') ?
                                    <MenuItem key="shelf" icon={<ShelfMgmtIcon />}>
                                        <Link to={getShelfBaseRoute()}>
                                            <span className={classes.menuItem}>
                                                {t('shelf_management')}
                                            </span>
                                        </Link>
                                    </MenuItem> : null}
                                { displayMenuItem('supplementoryServices') ?
                                    <MenuItem key="supplementoryServices" icon={<MiddleMileOverviewIcon />}>
                                        <Link to={getSupplementoryServicesRoute()}>
                                            <span className={classes.menuItem}>
                                                {t('Supplementary Services')}
                                            </span>
                                        </Link>
                                    </MenuItem> : null}
                                { displayMenuItem('network') ?
                                    <MenuItem key="network" icon={<GlobalOutlined />}>
                                        <Link to={getNetworkRoute()}>
                                            <span className={classes.menuItem}>
                                                {t('Network')}
                                            </span>
                                        </Link>
                                    </MenuItem> : null}
                        </Menu>
                    );
                case 'FinanceOperations':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            selectable={false}
                            className={classes.submenu}
                            onClick={this.closeDrawer}
                            triggerSubMenuAction="hover"
                            selectedKeys={getSelectedKeys()}
                            theme="light">
                            { displayMenuItem('riderReconciliation') ?
                                <MenuItem key="riderReconciliation" icon={<RiderReconnIcon />}>
                                    <Link to={getRiderReconciliationRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('rider_reconciliation')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('carrier_reconciliation') ?
                                <MenuItem key="carrier_reconciliation" icon={<ThirdPartyReconciliationIcon />}>
                                    <Link to={getCarrierReconciliationRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('third_party_reconciliation')} 
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('transaction') ?
                                <MenuItem key="transaction" icon={<TransactionHistoryIcon />}>
                                    <Link to={masterData?.cash_and_order_recon_v2 ? getFoodDashboardCODCashSettlement() : getTransactionsHistoryRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('rider_transactions_history')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('carrier_transactions') ?
                                <MenuItem key="carrier_transactions" icon={<ThirdPartyTransactionHistoryIcon />}>
                                    <Link to={getCarrierTransactionsHistoryRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('third_party_transactions')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('bankDeposit') ?
                                <MenuItem key="bankDeposit" icon={<BankDepositIcon />}>
                                    <Link to={getBankDepositRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('bank_deposit')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('bankTransaction') ?
                                <MenuItem key="bankTransaction" icon={<BankDepositHistoryIcon />}>
                                    <Link to={getBankTransactionsHistoryRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('bank_transactions_history')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('cashRemittance') ?
                                <MenuItem key="cashRemittance" icon={<RemittanceIcon />}>
                                    <Link to={getCashRemittanceRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('remittance')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('invoices') ?
                                <MenuItem key="invoices" icon={<InvoiceIcon />}>
                                    <Link to={getInvoiceRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('invoices')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('supply_request') ?
                                <MenuItem key="supply_request" icon={<SupplyIcon />}>
                                    <Link to={getSupplyRequestRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('supply_request')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('PISSummary') ?
                                <MenuItem key="PISSummary" icon={<PISSummaryIcon />}>
                                    <Link to={getPISSummaryRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('pis_summary')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('vendorPayout') ?
                                <MenuItem key="vendorPayout" icon={<VendorPayoutIcon />}>
                                    <Link to={getVendorPayoutRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('vendor_payout')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('valletTransactions') ?
                            <MenuItem key="valletTransactions" icon={<PISSummaryIcon />}>
                                <Link to={getValletTransactionsRoute()}>
                                    <span className={classes.menuItem}>
                                        {t('wallet_transactions')}
                                    </span>
                                </Link>
                            </MenuItem> : null}
                            { displayMenuItem('managePIS') ?
                                <MenuItem key="managePIS" icon={<ManagePISIcon />}>
                                    <Link to={getManagePISRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('manage_pis')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('codSettlement') ?
                                <MenuItem key="codSettlement" icon={<CODSettlementIcon />}>
                                    <Link to={getFoodDashboardCODCashSettlement()}>
                                        <span className={classes.menuItem}>
                                            {t('cod_settlement')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                        </Menu>
                    );
                case 'thirdPartySubmenu':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            className={classes.submenu}
                            selectable={false}
                            onClick={this.closeDrawer}
                            triggerSubMenuAction="hover"
                            selectedKeys={getSelectedKeys()}
                            theme="light">
                                { displayMenuItem('thirdparty') ?
                                <MenuItem key="thirdparty" icon={<ConsolidatedIcon />}>
                                    <Link to={getThirdPartyViewRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('carrier_dispatch')}
                                        </span>
                                    </Link>
                                </MenuItem> : null
                                }
                                { displayMenuItem('domesticTracking') ?
                                    <MenuItem key="domesticTracking" icon={<TrackingIcon />}>
                                        <Link to={getDomesticTrackRoute()}>
                                            <span className={classes.menuItem}>
                                                {/* {showThirdParty ? 'Third Party Tracking' : 'Tracking'} */}
                                                {showThirdParty ? t('carrier_tracking') : t('tracking')}
                                            </span>
                                        </Link>
                                    </MenuItem> : null
                                }
                                { displayMenuItem('priceCalculator') ?
                                    <MenuItem key="priceCalculator" icon={<PriceCalculatorIcon />}>
                                        <Link to={getPriceCalculatorRoute()}>
                                            <span className={classes.menuItem}>
                                                {t('price_calculator')}
                                            </span>
                                        </Link>
                                    </MenuItem> : null
                                }
                        </Menu>
                    );
                case 'AnalyticsSubmenu':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            selectable={false}
                            onClick={this.closeDrawer}
                            className={classes.submenu}
                            triggerSubMenuAction="hover"
                            selectedKeys={getSelectedKeys()}
                            theme="light">
                                {displayMenuItem('expressAnalytics') ?
                                <MenuItem key="expressAnalytics" icon={<AnalyticsOverviewIcon />}>
                                    <Link to={getRetailAnalyticsOverviewRoute()}>
                                        <span className={classes.menuItem}>{t('overview')}</span>
                                    </Link>
                                </MenuItem> : null}
                                {displayMenuItem('overview_analytics') ?
                                <MenuItem key="overview_analytics" icon={<AnalyticsOverviewIcon />}>
                                    <Link to={'/retail/overview'}>
                                        <span className={classes.menuItem}>{t('overview')}</span>
                                    </Link>
                                </MenuItem> : null}
                                {displayMenuItem('ltlAnalytics') ?
                                    <MenuItem key="ltlAnalytics" icon={<AnalyticsOverviewIcon />}>
                                        <Link to={getLTLAnalyicsRoute()}>
                                            <span className={classes.menuItem}>
                                            {t('analytics')}
                                            </span>
                                        </Link>
                                    </MenuItem>
                                    : null}
                                {displayMenuItem('analytics') ?
                                <MenuItem key="analytics" icon={<AnalyticsOverviewIcon />}>
                                    <Link to={getFoodDashboardAnalytics()}>
                                        <span className={classes.menuItem}>{t('analytics')}</span>
                                    </Link>
                                </MenuItem> : null}
                                {displayMenuItem('analyze') ?
                                <MenuItem key="analyze" icon={<AnalyticsAnalyzeIcon />}>
                                    <a href={'/analytics'} target="_blank">
                                        <span className={classes.menuItem}>{t('analyze')}</span>
                                    </a>
                                </MenuItem> : null}
                                {displayMenuItem('queryBuilder') ?
                                <MenuItem key="queryBuilder" icon={<BIIcon />}>
                                    <Link to={ANALYTICS_BASE_PATH}>
                                        <span className={classes.menuItem}>{t('Business Intelligence')}</span>
                                        <Tag className={classes.newTag} color="#E7F9FE">{t('new')}</Tag>
                                    </Link>
                                </MenuItem> : null}
                                {displayMenuItem('generic-download-requests') ?
                                <MenuItem key="generic-download-requests" icon={<DownloadOutlined/>}>
                                    <Link to={getGenericDownloadRequestsRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('download_requests')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                        </Menu>
                    );
                case 'LIASubmenu':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            selectable={false}
                            onClick={this.closeDrawer}
                            className={classes.submenu}
                            triggerSubMenuAction="hover"
                            selectedKeys={getSelectedKeys()}
                            theme="light">
                                {displayMenuItem('copilot') ?
                                <MenuItem key="copilot" icon={<LIAIcon />}>
                                    <Link to={LIA_CO_PILOT_BASE_PATH}>
                                        <span className={classes.menuItem}>{t('LIA Co-Pilot')}</span>
                                    </Link>
                                </MenuItem> : null}
                                {displayMenuItem('planeIntegration') ?
                                <MenuItem key="copilot" icon={<LIACoPilotIcon />}>
                                    <Link to={getPlaneTicketsRoute()} target='_blank'>
                                        <span className={classes.menuItem}>{t('Support Tickets')}</span>
                                    </Link>
                                </MenuItem> : null}
                        </Menu>
                    );
                case 'shipsyAISubmenu':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            selectable={false}
                            onClick={this.closeDrawer}
                            className={classes.submenu}
                            triggerSubMenuAction="hover"
                            selectedKeys={getSelectedKeys()}
                            theme="light">
                                {displayMenuItem('routingPlayground') ?
                                <MenuItem key="routingPlayground" icon={<RoutingPlaygroundIcon />}>
                                    <Link to={getRoutingPlaygroundRoute()}>
                                        <span className={classes.menuItem}>{t('routing_playground')}</span>
                                    </Link>
                                </MenuItem> : null}
                                {displayMenuItem('territoryOptimization') ?
                                <MenuItem key="territoryOptimization" icon={<TerritoryOptimizationIcon />}>
                                    <Link to={getTerritoryOptimizationRoute()}>
                                        <span className={classes.menuItem}>{t('territory_optimisation')}</span>
                                    </Link>
                                </MenuItem> : null}
                                {displayMenuItem('geocodingPlayground') ?
                                <MenuItem key="geocodingPlayground" icon={<GeocodingPlaygroundIcon />}>
                                    <Link to={getGeocodingPlaygroundRoute()}>
                                        <span className={classes.menuItem}>{t('geocoding_playground')}</span>
                                    </Link>
                                </MenuItem> : null}
                        </Menu>
                    );
                case 'integrationSubmenu':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            selectable={false}
                            onClick={this.closeDrawer}
                            className={classes.submenu}
                            triggerSubMenuAction="hover"
                            selectedKeys={getSelectedKeys()}
                            theme="light">
                            { displayMenuItem('integrationMarketplace') ?
                                <MenuItem key="integrationMarketplace" icon={<IntegrationMarketplaceIcon />}>
                                    <Link to={getIntegrationMarketplaceRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('integration_marketplace')}
                                        </span>
                                    </Link>
                            </MenuItem> : null }
                            { displayMenuItem('erpSync') ?
                                <MenuItem key="erpSync" icon={<IntegrationLogsIcon />}>
                                    <Link to={getRetailERPSyncRoute()}>
                                        <span className={classes.menuItem}>
                                        {t('integration_logs')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            <MenuItem key="apiPlayground" icon={<APIPlaygroundIcon />}>
                                <Link to={API_PLAYGROUND_BASE_PATH}>
                                    <span className={classes.menuItem}>
                                        {t('api_playground')}
                                    </span>
                                </Link>
                            </MenuItem>
                        </Menu>
                    );
                case 'supportToolsSubmenu':
                    return (
                        <Menu
                            style={{ width: 256 }}
                            mode="inline"
                            selectable={false}
                            onClick={this.closeDrawer}
                            className={classes.submenu}
                            triggerSubMenuAction="hover"
                            selectedKeys={getSelectedKeys()}
                            theme="light"
                        >
                            {
                                displayMenuItem('retailSupportPortal') ?
                                    <MenuItem key="retailSupportPortal" icon={<SupportTeamPortalIcon />}>
                                        <Link to={getRetailSupportPortalRoute()}>
                                            <span className={classes.menuItem}>
                                                {t('support_team_portal')}
                                            </span>
                                        </Link>
                                    </MenuItem> : null
                            }
                            { displayMenuItem('devtools') ?
                                <MenuItem key="devtools" icon={<DevToolsIcon />}>
                                    <Link to={getDevToolsRoute()}>
                                        <span className={classes.menuItem}>
                                            {t('dev_tools')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                            { displayMenuItem('helpdeskNotifications') ?
                                <MenuItem key="helpdeskNotifications" icon={<HelpdeskNotificationIcon />}>
                                    <Link to={getFoodDashboardNotifications()}>
                                        <span className={classes.menuItem}>
                                            {t('helpdesk_notifications')}
                                        </span>
                                    </Link>
                                </MenuItem> : null}
                        </Menu>
                    );
                case 'setupSubmenu':
                    console.log('setupSubmenu', checkUsingOndemand);
                    return (
                        <Menu
                            style={{ width: 256, border: 'none' }}
                            mode="inline"
                            className={classes.submenu}
                            selectable={false}
                            onClick={this.closeDrawer}
                            triggerSubMenuAction="hover"
                            key="someUniqueKey"
                            openKeys={[(this.state.openSubMenuKeys || this.state.subMenuKeySelected)]}
                            selectedKeys={getSelectedKeys()}
                            theme="light">
                                {
                                (checkUsingOndemand ? SetupSubmenuOnDemand : SetupSubmenu).map((submenuItem) => {
                                    const {title, subitems, icon: SubmenuIcon, translationKey: itemTransKey } = submenuItem;
                                    if (!showSetupSubmenu(subitems)) return null;
                                    return (
                                        <Menu.SubMenu
                                            key={title}
                                            title={itemTransKey ? this.props.t(itemTransKey) : title}
                                            icon={<SubmenuIcon />}
                                            onTitleClick={() => handleSubMenuToggle(title)}>
                                            {
                                                subitems.map((childTitle) => {
                                                    if (!setupNavMenuItems[childTitle]) {
                                                        if (!setupMenuDetailsNew[childTitle]) {
                                                            return null;
                                                        }
                                                        const {isAllowed, icon: IconComponent, route, translationKey } = setupMenuDetailsNew[childTitle];
                                                        return (
                                                            (!isAllowed) || isAllowed(masterData) ?
                                                            (
                                                                <MenuItem key={childTitle} icon={<IconComponent />}>
                                                                    <Link to={route}>
                                                                        <span className={classes.menuItem}>
                                                                            {translationKey ? this.props.t(translationKey) : childTitle}
                                                                        </span>
                                                                    </Link>
                                                                </MenuItem>
                                                            ) : null
                                                        );
                                                    }
                                                    const {routeId, title: titleChild, icon: IconComponent, translationKey: transKey } = setupNavMenuItems[childTitle];
                                                    return (
                                                        shouldAllowSetupRoute(masterData, routeId) ?
                                                        (
                                                            <MenuItem key={childTitle} icon={<IconComponent />}>
                                                                <Link to={getFullRouteForId(routeId)}>
                                                                    <span className={classes.menuItem}>
                                                                    {transKey ? this.props.t(transKey) : titleChild}
                                                                    </span>
                                                                </Link>
                                                            </MenuItem>
                                                        ) : null
                                                    );
                                                    })
                                            }
                                        </Menu.SubMenu>
                                    );
                                })
                            }
                             <MenuItem key="ticketManagement" icon={<TicketIcon />} style={{gap:'10px'}}>
                                <Link to={TICKET_MANAGEMENT_BASE_PATH}>
                                    <span className={classes.menuItem}>
                                        {' '}{t('Ticket Management')}
                                    </span>
                                </Link>
                            </MenuItem>
                        </Menu>
                    );
                default: null;
            }
        };

        const showSubmenu = (key: string) => {
            switch(key) {
                case 'dashboardsSubMenu':
                    return displayMenuItem('dashboards');
                case 'ordersSubmenu':
                    return checkUsingOndemand;
                case 'consignmentSubmenu':
                    return !checkUsingOndemand;
                case 'FMandLMSubmenu':
                    if (displayMenuItem('opsv1pickupTaskList') || displayMenuItem('opsv1deliverytasklist') || displayMenuItem('tripmanager') || displayMenuItem('tracking') || displayMenuItem('retailOptimizationRequests') || displayMenuItem('custom_trip_creation_requests') || displayMenuItem('slot_management'))return true;
                    return false;
                case 'RiderAndVehicleSubmenu':
                    if (displayMenuItem('rider') || displayMenuItem('tmsVehicle') || displayMenuItem('rosterManagementOD') || displayMenuItem('riderManagementOD') || displayMenuItem('km_reading') || displayMenuItem('attendance') || displayMenuItem('vehicle_attendance'))return true;
                    return false;
                case 'MiddleMileSubMenu':
                    if (displayMenuItem('tmsIST') || displayMenuItem('shelf'))return true;
                    return false;
                case 'FinanceOperations':
                    if (displayMenuItem('transaction') || displayMenuItem('bankDeposit') || displayMenuItem('bankTransaction') || displayMenuItem('cashRemittance') || displayMenuItem('invoices') || displayMenuItem('valletTransactions')
                        || displayMenuItem('PISSummary') || displayMenuItem('vendorPayout') || displayMenuItem('managePIS') || displayMenuItem('codSettlement') || displayMenuItem('carrier_reconciliation') ||displayMenuItem('riderReconciliation') || displayMenuItem('carrier_transactions') || displayMenuItem('supply_request'))return true;
                    return false;
                case 'thirdPartySubmenu':
                    if (displayMenuItem('thirdparty') || displayMenuItem('domesticTracking') || displayMenuItem('priceCalculator'))return true;
                    return false;
                case 'AnalyticsSubmenu':
                    if (displayMenuItem('expressAnalytics') || displayMenuItem('overview_analytics') || displayMenuItem('analyze') || displayMenuItem('queryBuilder') || displayMenuItem('analytics') || displayMenuItem('generic-download-requests'))return true;
                    return false;
                case 'shipsyAISubmenu':
                    if (displayMenuItem('routingPlayground') || displayMenuItem('territoryOptimization') || displayMenuItem('geocodingPlayground'))return true;
                    return false;
                case 'supportToolsSubmenu':
                    if (displayMenuItem('retailSupportPortal') || displayMenuItem('devtools') || displayMenuItem('helpdeskNotifications'))return true;
                    return false;
                case 'setup':
                    if (applicationTypeOps || (checkUsingNewV2 && masterData.ops_dashboard_config.parts_to_show.setup) || checkUsingOndemand)return true;
                    return false;
                case 'LIASubmenu':
                    if (displayMenuItem('copilot')) return true;
                    return false;
                case 'landingPageHome':
                    if (displayMenuItem('landingPage')) return true;
                    return false;
                default: return true;
            }
        };
        const items = [
            {
              label: t('Business Intelligence'),
              key: 'AnalyticsSubmenu',
              icon: <BIIcon />,
              link: ANALYTICS_BASE_PATH,
            },
            {
              label: t('lia_copilot'),
              key: 'LIASubmenu',
              icon: <LIAIcon />,
              link: LIA_CO_PILOT_BASE_PATH,
            },
        ];
        /*
        if you want to add submenu then add a this key in your items array
        options: [
                { label: 'Business Intelligence', key: 'queryBuilder', icon: <BIIcon /> },
              ],
        */
        const renderMenuItem = (menuItem) => {
          if ('options' in menuItem) {
            return (
              <SubMenu
                key={menuItem.key}
                title={menuItem.label}
                icon={menuItem.icon}
                className={classes.mobileSubMenu}
              >
                {menuItem.options.map(option => renderMenuItem(option))}
              </SubMenu>
            );
          }
          return (
            <Menu.Item
              key={menuItem.key}
              icon={menuItem.icon}
              onClick={this.closeDrawer}
              className={classes.mobileMenu}
            >
              {menuItem.link ? (
                <Link to={menuItem.link}>
                  <spna className={classes.menuItem}>
                    {menuItem.label}
                  </spna>
                </Link>
              ) : menuItem.label}
            </Menu.Item>
          );
        };

        return (
          <>
            {!isNativeApp() ? (<Drawer
                placement="top"
                closable={false}
                onClose={() => {
                    this.setState({
                        currentPath: undefined,
                        menuKeySelected: undefined,
                        subMenuKeySelected: undefined,
                        subSubMenuKeySelected: undefined,
                    }, closeSideMenu);
                }}
                destroyOnClose
                visible={open}
                width={ this.state.submenuExists ? 516 : 256}
                className={classes.drawer}
                contentWrapperStyle={{ height: `${this.state.bodyHeight}px` }}
                afterOpenChange={() => this.loadSelectedMenuFromURL()}
                maskStyle={{
                    top: 0,
                    left: 0,
                    height: '100vh',
                    position: 'fixed',
                    background: `linear-gradient(transparent ${NAVBAR_HEIGHT}px, rgba(0, 0, 0, 0.45) 50px)`,
                }}
                style={{
                    top: NAVBAR_HEIGHT,
                    overflow: 'auto',
                    width: this.state.submenuExists ? 516 : 256,
                    maxHeight: `calc( 100vh - ${NAVBAR_HEIGHT}px)`,
                }}>
                <div className={classes.flexContainer}>
                <div className={classes.flexItemMenu} ref={this.menuRef}>
                {
                    <Menu
                        style={{ width: 256, border: 'none' }}
                        mode="inline"
                        className={classes.mainMenu}
                        selectable={false}
                        onClick={this.onMenuClick}
                        triggerSubMenuAction="hover"
                        onMouseLeave={() => this.setState({collapseMenu: true})}
                        expandIcon={null}
                        selectedKeys={[this.state.menuKeySelected]}
                        // defaultOpenKeys={['consignmentSubmenu']}
                        theme="light">
                        { showSubmenu('landingPageHome') ?
                            <MenuItem key="landingPageHome" icon={<HomeIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                                <span className={classes.menuItem}>
                                    {t('crm_landing_page_home')}
                                </span>
                            </MenuItem>
                        : null}
                        { showSubmenu('ordersSubmenu') ?
                            <MenuItem key="ordersSubmenu" icon={<ConsignmentIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                                <span className={classes.menuItem}>
                                {t('orders')}
                                </span>
                            </MenuItem>
                        : null}
                        { showSubmenu('consignmentSubmenu') ?
                            <MenuItem key="consignmentSubmenu" icon={<ConsignmentIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                                <span className={classes.menuItem}>
                                    {t('consignments')}
                                </span>
                            </MenuItem>
                        : null}
                        { showSubmenu('FMandLMSubmenu') ?
                            <MenuItem key="FMandLMSubmenu" icon={<FMLMIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                                <span className={classes.menuItem}>
                                    {t('fm_and_lm_trips')}
                                </span>
                            </MenuItem>
                        : null}
                        { showSubmenu('RiderAndVehicleSubmenu') ?
                            <MenuItem key="RiderAndVehicleSubmenu" icon={<RiderVehicleIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                                <span className={classes.menuItem}>
                                    {t('rider_and_vehicle')}
                                </span>
                            </MenuItem>
                        : null}
                        { showSubmenu('MiddleMileSubMenu') ?
                        <MenuItem key="MiddleMileSubMenu" icon={<MiddleMileIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                            <span className={classes.menuItem}>
                                {t('middle_mile')}
                            </span>
                        </MenuItem>
                        : null}
                        { showSubmenu('FinanceOperations') ?
                        <MenuItem key="FinanceOperations" icon={<FinanceOperationIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                            <span className={classes.menuItem}>
                                {t('finance_operations')}
                            </span>
                        </MenuItem>
                        : null}
                        { showSubmenu('thirdPartySubmenu') ?
                        <MenuItem key="thirdPartySubmenu" icon={<ThirdPartyIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                            <span className={classes.menuItem}>
                                {t('third_party')}
                            </span>
                        </MenuItem>
                        : null}
                        { showSubmenu('AnalyticsSubmenu') ?
                        <MenuItem key="AnalyticsSubmenu" icon={<AnalyticsIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                            <span className={classes.menuItem}>
                                {t('analytics')}
                            </span>
                        </MenuItem>
                        : null}
                        { showSubmenu('LIASubmenu') ?
                            <MenuItem key="LIASubmenu" icon={<LIACoPilotIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                                <span className={classes.menuItem}>
                                    {t('Tickets')}
                                </span>
                            </MenuItem>
                            : null}
                        { showSubmenu('shipsyAISubmenu') ?
                        <MenuItem key="shipsyAISubmenu" icon={<ShipsyAIIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                            <span className={classes.menuItem}>
                                {t('shipsy_ai')}
                            </span>
                        </MenuItem>
                        : null}
                        { showSubmenu('integrationSubmenu') ?
                        <MenuItem key="integrationSubmenu" icon={<IntegrationsIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                            <span className={classes.menuItem}>
                                {t('integrations')}
                            </span>
                        </MenuItem>
                        : null}
                        { showSubmenu('supportToolsSubmenu') ?
                            <MenuItem key="supportToolsSubmenu" icon={<SupportToolsIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                                <span className={classes.menuItem}>
                                    {t('support_tools')}
                                </span>
                            </MenuItem>
                            : null}
                        { showSubmenu('dashboardsSubMenu') ?
                            <MenuItem key="dashboardsSubMenu" icon={<ConsignmentIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                                <span className={classes.menuItem}>
                                {t('moreDashboards')}
                                </span>
                            </MenuItem>
                        : null}
                        { showSubmenu('setupSubmenu') ?
                            <MenuItem key="setupSubmenu" icon={<SetupIcon />} onTitleClick={this.onMenuClick} style={{ height: this.state.isMenuSizeBig ? 40 : undefined }}>
                                <span className={classes.menuItem}>
                                    {t('setup')}
                                </span>
                            </MenuItem>
                        : null}
                        {applicationTypeCRM && showMetrics ?
                            <MenuItem key="summaryMetrics">
                                <Link to={getSummaryMetricsRoute()}>
                                    <span className={classes.menuItem}>
                                        {t('summary_metrics')}
                                    </span>
                                </Link>
                            </MenuItem> : null}
                        {/* {(applicationTypeOps) ?
                            <MenuItem key="setup">
                                <Link to={'/dashboard/setup'}>
                                    <span className={classes.menuItem}>
                                        Setup
                                    </span>
                                </Link>
                            </MenuItem> : null} */}
                        {/* {showViewTypeSwitch ?
                            <Menu.Item key="switchViewType" className={classes.borderTop}>
                                <SwitchViewType />
                            </Menu.Item> : null} */}
                        {/* <MenuItem key="logout" onClick={() => {
                            logoutUserAndClearCache();
                        }}>
                            <span className={classes.menuItem}>
                                Logout
                            </span>
                        </MenuItem> */}
                    </Menu>
                }
                </div>
                <div className={classes.separator}  style={{ height: `${this.state.bodyHeight - 24}px` }}></div>
                <div className={classes.flexItemSubmenu}>
                    {this.state.menuKeySelected ? submenu(this.state.menuKeySelected) : null}
                </div>
                </div>
                <div className={classes.searchShortcut}>{t('search_hint')}</div>
            </Drawer>):(
            <>
              <Drawer
                className={classes.mobileDrawer}
                title={''}
                placement={'left'}
                width={'78%'}
                onClose={() => {
                  this.setState({
                    currentPath: undefined,
                    menuKeySelected: undefined,
                    subMenuKeySelected: undefined,
                    subSubMenuKeySelected: undefined,
                  }, closeSideMenu);
                }}
                headerStyle={{ backgroundColor: theme.colors.globalHeader, padding:'18px 8px', width: '40px' }}
                visible={open}
                closeIcon={<CloseOutlined style={{color: theme.colors.textOnDarkBg}}/>}
              >
                  <Menu
                    onClick={this.onMenuClick}
                    triggerSubMenuAction="hover"
                    onMouseLeave={() => this.setState({collapseMenu: true})}
                    theme="light"
                    className={classes.mobileMenu}
                    style={{ border: 'none' }}
                    mode="inline"
                  >
                    {items.map(item => renderMenuItem(item))}
                  </Menu>
              </Drawer>
            </>)
            }
          </>
        );
    }
}

const mapStateToProps = ({ masterData, navigation, genericPageReducer }, ownProps) => {
    return {
        masterData,
        open: navigation.sideMenuOpen,
        navLogoURL: masterData.logoUrl,
        showOptimizationRequests: masterData.auto_allocation_asynchronous_request || false,
        showRetailOptions: masterData.show_retail_options,
        showLtlOptions: masterData.show_ltl_options,
        showRetailOverviewDashboard: masterData.show_retail_overview_analytics,
        showMetrics: masterData.show_summary_metrics,
        showAnalytics: Boolean(masterData.ltl_analytics_embed_link &&
            masterData.ltl_analytics_embed_link.length > 0),
        showBankDeposit: masterData.show_bank_deposit_crm,
        showV1RemittanceDashboard: masterData?.use_remittance_module_v1 || false,
        showV2RemittanceDashboard: masterData?.use_remittance_module_v2 || false,
        showThirdParty: masterData.show_third_party_options,
        viewType: masterData.viewType,
        showOpsV1DeliveryTaskList: masterData.parts_for_ops_dashboard ?
            masterData.parts_for_ops_dashboard.show_opsV1_delivery_task_list_in_v2 || false : false,
        showLogisticsExpressOptions: masterData.show_logistics_express_options,
        showCRM: masterData?.ops_dashboard_config?.parts_to_show?.show_crm,
        showHybridView: masterData.show_logistics_hybrid_express_view,
        showCounterBooking: masterData.single_consignment_booking_config?.show_on_ops_v2,
        showCallsOverview: masterData.ops_dashboard_config?.parts_to_show?.show_call_summary_page,
        showMyCalls: masterData.ops_dashboard_config?.parts_to_show?.show_my_calls_page,
        showViewTypeSwitch: masterData.show_view_type_switch,
        showSupplementoryServices: masterData.allow_supplementory_services_read || false,
        showOndemandOptions: masterData.show_ondemand_options,
        viewTypeForSwitchView: ownProps?.viewType || masterData?.viewType,
        showGenericSideMenu: masterData?.ops_dashboard_config?.parts_to_show?.ops_sidemenu_list_enable,
        showVehicleRequest: masterData?.allow_vehicle_request_create || masterData?.allow_vehicle_request_review || false,
        employee_pages: Object.values(genericPageReducer),
        employeePermissions: masterData.ops_dashboard_config?.parts_to_show
                ?.employee_permissions || {},
        showCarrierPartner: masterData.ops_dashboard_config?.parts_to_show?.setup_carrier_partner ||
            masterData.setup_to_show_in_opsv2?.carrier_partner || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            closeSideMenu,
        },
        dispatch,
    );
};

const SideMenuStyled = withStyles(styles, { injectTheme: true })(SideMenu);
export default withTranslation()(withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(SideMenuStyled),
) as any);
